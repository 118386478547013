import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import EventService from "redux/services/EventService";

const initialState = {
    loading: false,
    eventList: [],
    errorMessage: ""
}

export const retriveEventList = createAsyncThunk(
    "eventSlice/retriveEventList",
    async ({ userData }, { rejectWithValue }) => {
        try {
            const res = await EventService.getAll(userData);
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response.data);;
        }

    }
);

export const createEvent = createAsyncThunk(
    "eventSlice/createEvent",
    async ({ data }, { rejectWithValue }) => {
        try {
            const res = await EventService.create(data);
            // dispatch(retriveEventList({ userData }))
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response.data);;
        }
    }
);

export const updateEvent = createAsyncThunk(
    "eventSlice/updateEvent",
    async ({ data }, { rejectWithValue }) => {
        try {
            const res = await EventService.update(data);
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response.data);;
        }
    }
);

export const deleteEvent = createAsyncThunk(
    "eventSlice/deleteEvent",
    async ({ data }, { rejectWithValue }) => {
        try {
            const res = await EventService.remove(data);
            return res.data;
        } catch (error) {
            return rejectWithValue(error.response.data);;
        }

    }
);

export const getEventReport = createAsyncThunk(
    "memeberShipSlice/getEventReport",
    async ({ userData }, { rejectWithValue }) => {
        try {
            const res = await EventService.getMembersReport(userData);
            return res.data;
        } catch (error) {
            return rejectWithValue(error.response.data);;
        }

    }
);


const eventSlice = createSlice({
    name: "eventList",
    initialState,
    extraReducers: {
        [retriveEventList.pending]: (state) => {
            state.loading = true
        },
        [retriveEventList.fulfilled]: (state, { payload }) => {
            state.loading = false
            if (payload.succeed) {
                state.eventList = [...payload.value]
            } else {
                state.loading = false
                state.errorMessage = payload.error;
            }

        },
        [retriveEventList.rejected]: (state, { payload }) => {
            state.loading = false
            state.errorMessage = payload;
        },
        [createEvent.fulfilled]: (state, { payload }) => {
            return payload
        },
        [createEvent.rejected]: (state) => {
            state.loading = false
        },
        [updateEvent.fulfilled]: (state, { payload }) => {
            state.loading = false
            if (payload.succeed) {
                const index = state.eventList.findIndex(x => x.id === payload.value.EventRecId);
                state[index] = {
                    ...state[index],
                    payload,
                };
            } else {
                state.loading = false
                state.errorMessage = payload.error;
            }
        },
        [updateEvent.rejected]: (state, { payload }) => {
            return payload;
        },
        [deleteEvent.fulfilled]: (state, { payload }) => {
            return payload;
        },
        [deleteEvent.rejected]: (state, { payload }) => {
            return payload;
        },
        [getEventReport.fulfilled]: (state, { payload }) => {
            return payload;
        },
    }
})

const { reducer } = eventSlice;
export default reducer;