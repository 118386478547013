import YMAPI from "../../http-common";

const getAll = data => {
    return YMAPI.get(`EventRegistration/GetAllByChapterId?ChapterRecId=${data.ChapterRecId}&UserRecId=${data.UserRecId}`);
};

const verifyEventRegistration = data => {
    return YMAPI.post(`Event/VerifyEventRegistration`, data);
};

const getEventRegisteredReport = data => {
    return YMAPI.get(`Report/GetEventRegisteredMemberListReport?ChapterRecId=${data.ChapterRecId}&UserRecId=${data.UserRecId}`)
}

const remove = (data) => {
    return YMAPI.post(`EventRegistration/DeleteRegisteredMember`, data);
};


const MemeberShipService = {
    getAll,
    verifyEventRegistration,
    getEventRegisteredReport,
    remove
};

export default MemeberShipService;