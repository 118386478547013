import { GenericAPI, useCallbackAsync } from "services/GenericApiService"
import { getToken } from 'utils/userSession'
export function getData(url) {
    const config = {
        method: "GET",
        headers: { "Accept": "application/json", "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` },
    }
    return (
        GenericAPI(url, config)
            .then((response) => {
                if (response) {
                    return response;
                }
            })
            .catch((err) => {
                return err;;
            })
    )
}

export function postData(url, body, isCredentials) {
    const config = {
        method: "POST",
        headers: { "Accept": "application/json", "Content-Type": "application/json" },
        body: JSON.stringify(body)
    }
    if (isCredentials) config.credentials = "include";
    return (
        GenericAPI(url, config)
            .then((response) => {
                if (response) {
                    return response;
                }
            })
            .catch((err) => {
                return err;;
            })
    )
}
export function putData(url, body) {
    const config = {
        method: "PUT",
        headers: { "Accept": "application/json", "Content-Type": "application/json" },
        body: JSON.stringify(body)
    }
    return (
        GenericAPI(url, config)
            .then((response) => {
                if (response) {
                    return response;
                }
            })
            .catch((err) => {
                return err;;
            })
    )
}
export function deleteData(url, body) {
    const config = {
        method: "DELETE",
        headers: { "Accept": "application/json", "Content-Type": "application/json" },
        body: JSON.stringify(body)
    }
    return (
        GenericAPI(url, config)
            .then((response) => {
                if (response) {
                    return response;
                }
            })
            .catch((err) => {
                return err;;
            })
    )
}


