
import React, { useEffect, Suspense } from "react";
import { Route, useHistory, Redirect } from 'react-router-dom';
import { isAuthenticated } from "utils/userSession";
import LoadingScreen from 'layouts/LoadingScreen'
// const PrivateRoute = ({ children, ...rest }) => {
//     console.log("Private Route run")
//     const history = useHistory();
//     useEffect(() => {
//         const onMount = async () => {

//             if (!isAuthenticated()) history.push('/'); // if its not valid redirect to login
//         };
//         onMount(); // call function inside use effect hook
//     }, []);

//     return <Route {...rest}>{children}</Route>; // pass down any router props and render the children components
// };
// export default PrivateRoute;




const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Suspense fallback={<LoadingScreen />}>
            <Route {...rest} render={props => (
                isAuthenticated() ?
                    <Component {...props} />
                    : <Redirect to="/" />
            )} /></Suspense>
    );
};
export default PrivateRoute;