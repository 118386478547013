import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "redux/services/UserService";

const initialState = {};

export const getCurrentUser = createAsyncThunk(
    "usersSlice/getCurrentUser",
    async ({ id }) => {
        const res = await UserService.getCurrentUser(id);
        return res.data;
    }
);

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        Logout: state => {
        }
    },
    extraReducers: {
        [getCurrentUser.fulfilled]: (state, action) => {
            return action.payload
        },
    }
})

export const { Logout } = usersSlice.actions;

const { reducer } = usersSlice;
export default reducer;