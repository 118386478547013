import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import EventRegistrationService from "redux/services/EventRegistrationService";

const initialState = {
    loading: false,
    registeredList: [],
    errorMessage: ""
}

export const retriveRegisteredList = createAsyncThunk(
    "eventSlice/retriveRegisteredList",
    async ({ userData }, { rejectWithValue }) => {
        try {
            const res = await EventRegistrationService.getAll(userData);
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response.data);;
        }

    }
);

export const verifyEventRegistration = createAsyncThunk(
    "eventSlice/verifyEventRegistration",
    async ({ data }, { rejectWithValue }) => {
        try {
            const res = await EventRegistrationService.verifyEventRegistration(data);
            return res.data;
        } catch (err) {
            return rejectWithValue(err.response.data);;
        }

    }
);

export const getEventRegisteredReport = createAsyncThunk(
    "memeberShipSlice/getEventRegisteredReport",
    async ({ userData }, { rejectWithValue }) => {
        try {
            const res = await EventRegistrationService.getEventRegisteredReport(userData);
            return res.data;
        } catch (error) {
            return rejectWithValue(error.response.data);;
        }

    }
);

export const deleteRegisteredMember = createAsyncThunk(
    "eventSlice/deleteRegisteredMember",
    async ({ data }, { rejectWithValue }) => {
        try {
            const res = await EventRegistrationService.remove(data);
            return res.data;
        } catch (error) {
            return rejectWithValue(error.response.data);;
        }

    }
);



const eventRegistrationSlice = createSlice({
    name: "registeredList",
    initialState,
    extraReducers: {
        [retriveRegisteredList.pending]: (state) => {
            state.loading = true
        },
        [retriveRegisteredList.fulfilled]: (state, { payload }) => {
            state.loading = false
            if (payload.succeed) {
                state.registeredList = [...payload.value]
            } else {
                state.loading = false
                state.errorMessage = payload.error;
            }

        },
        [retriveRegisteredList.rejected]: (state, { payload }) => {
            state.loading = false
            state.errorMessage = payload;
        },
        [verifyEventRegistration.pending]: (state) => {
            state.loading = true
        },
        [verifyEventRegistration.fulfilled]: (state, { payload }) => {
            return payload;
        },
        [verifyEventRegistration.rejected]: (state, { payload }) => {
            state.loading = false
            state.errorMessage = payload;
        },
    }
})

const { reducer } = eventRegistrationSlice;
export default reducer;