import { faWindows } from "@fortawesome/free-brands-svg-icons";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Cookie from 'universal-cookie';

const cookies = new Cookie();


//window._ymUrl = "https://localhost:44341/";
//window._ymUrl = "https://adminuat.ymmississauga.ca/";      //test after deploy
export const isAuthenticated = () => {
    if (cookies.get('access_token') && localStorage.getItem("UserId")) {
        return cookies.get('access_token')
    }
    else {
        return false
    }
}

export const setCurrentUserIdGlobal = (userId) => {
    localStorage.setItem('UserId', userId);
    window.UserId = userId;
}

export const getToken = () => {
    return cookies.get('access_token')
}


export const getUserInfo = (key) => {
    return localStorage.getItem(key);
}


export const isAdmin = () => {
    return localStorage.getItem('isAdmin');
}


export const CleanSession = () => {
    localStorage.removeItem('UserId');
    const history = useHistory();
    const goLogin = () => history.push('/');
    goLogin();
}

