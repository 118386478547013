import YMAPI from "../../http-common";

const getAll = data => {
    return YMAPI.get(`Membership/GetAllMembers?ChapterRecId=${data.ChapterRecId}&UserRecId=${data.UserRecId}`);
};

const create = data => {
    return YMAPI.post("Membership/CreateMembership", data);
};

const update = (data) => {
    return YMAPI.post(`Membership/UpdateMembership`, data);
};

const remove = (data) => {
    return YMAPI.post(`Membership/DeleteMembership`, data);
};
const sendEmail = data => {
    return YMAPI.post(`Email/SendEmail`, data);
}

const getMembersReport = data => {
    return YMAPI.get(`Report/GetMembershipListReport?ChapterRecId=${data.ChapterRecId}&UserRecId=${data.UserRecId}`)
}


const MemeberShipService = {
    getAll,
    create,
    update,
    remove,
    sendEmail,
    getMembersReport

};

export default MemeberShipService;