
import React, { useEffect } from "react";
const Dashboard = React.lazy(() => import('views/Dashboard/Dashboard.js'));
const Membership = React.lazy(() => import('views/Membership/Membership.js'));
const MembershipForm = React.lazy(() => import('views/Membership/MembershipForm'));
const EventDetail = React.lazy(() => import('views/Event/EventDetail'));
const VerifyRegisteredMembers = React.lazy(() => import('views/Event/VerifyRegisteredMembers'));
const RegisterMemberList = React.lazy(() => import('views/Event/RegisterMemberList'));

// import Dashboard from "views/Dashboard/Dashboard.js";
// import Membership from "views/Membership/Membership.js";
// import MembershipForm from "views/Membership/MembershipForm";
// import EventDetail from "views/Event/EventDetail";
// import VerifyRegisteredMembers from "views/Event/VerifyRegisteredMembers";
// @material-ui/icons.
import DashboardIcon from "@material-ui/icons/Dashboard";
import GroupIcon from '@mui/icons-material/Group';
import EventIcon from '@mui/icons-material/Event';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/membership",
    name: "Membership",
    icon: GroupIcon,
    component: Membership,
    layout: "/admin",
  },
  {
    path: "/event",
    name: "Events",
    icon: EventIcon,
    component: EventDetail,
    layout: "/admin",
  },
  {
    path: "/registememberlist",
    name: "Registered Member List",
    icon: AppRegistrationIcon,
    component: RegisterMemberList,
    layout: "/admin",
  },
  {
    path: "/verifyregisteredmembers",
    name: "Verify Registered Members",
    icon: EventAvailableIcon,
    component: VerifyRegisteredMembers,
    layout: "/admin",
  }
];
export default dashRoutes;
