import YMAPI from "../../http-common";

const getCurrentUser = RecId => {
    return YMAPI.get(`User/GetCurrentUser?RecId=${RecId}`);
};

const Logout = () => {
    return YMAPI.post(`User/Logout`);
}
const UserService = {
    getCurrentUser,
    Logout
};
export default UserService;