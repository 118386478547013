import axios from "axios";
import { getToken } from "utils/userSession";
import { YM_AdminURL } from 'config/CONSTANTS'
const url = YM_AdminURL + "api/";
const YMAPI = axios.create({
    baseURL: url,
    headers: {
        'Content-type': "application/json",
        'Authorization': `Bearer ${getToken()}`
    },
    withCredentials: true
});

export default YMAPI 