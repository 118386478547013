import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";


// @material-ui/icons

import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// core components

import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import UserService from "redux/services/UserService";
import { Logout } from 'redux/slices/usersSlice'
import { useDispatch, useSelector } from "react-redux";

import { CleanSession } from "utils/userSession"

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const dispatch = useDispatch();
  const [isLogOut, setLogOut] = useState(false);
  const classes = useStyles();

  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  const handleCloseProfile = (e) => {
    e.preventDefault();
    UserService.Logout().then((res) => {
      if (res.data.success) {
        setLogOut(true);
      }
    });
  };

  if (isLogOut) {
    dispatch(Logout());
    CleanSession();
  }
  return (
    <div>
      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="LogOut"
          justIcon
          aria-haspopup="true"
          onClick={handleCloseProfile}
          className={classes.buttonLink}
        >
          <ExitToAppIcon
            className={
              classes.headerLinksSvg +
              " " + classes.links
            }
          />

        </Button>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
